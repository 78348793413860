%overlay {
  @include overlay; }

%coverdiv {
  @include coverdiv; }

%cover {
  @include cover; }

%clr {
  @include clr; }

%hidden {
  @include hidden; }

%visible {
  @include visible; }

%vcenter {
  @include vcenter; }

%text-ovdrflow {
  @include text-overflow; }

%hard-hidden {
  display: none; }

%row {
  display: flex;
  flex-wrap: wrap; }

%coverimage {
  width: 100%;
  height: 100%;
  object-fit: cover; }

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center; }

