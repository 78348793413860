.prev,
.next {
  @extend %flex-center;

  width: 30px;
  height: 30px;

  @include before {
    @include icon-arrow(1, 1, .3, currentColor, 45, em);
    display: block; } }

.next {
  transform: scaleX(-1); }

.slider {
  &__wrap {
    position: relative;
    padding-left: 50px;
    padding-right: 50px;

    @include sm {
      padding-left: 30px;
      padding-right: 30px; } }

  &__prev,
  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translate(0, -50%);

    &[disabled] {
      opacity: 0.5; } }

  &__next {
    right: 0;
    transform: translate(0, -50%) scaleX(-1); }

  &__prev {
    left: 0; } }

.slide {
  &__inner {
    position: relative;
    padding-top: percentage(608 / 756);

    img {
      @extend %coverdiv;
      @extend %coverimage; } } }
