.layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__header {
    position: absolute;
    z-index: $z-index-controls;
    left: 0;
    right: 0;
    top: 0; }

  &__main {
    flex-grow: 1;
 }    // padding-top: $header-height + px

  &__footer {} }

