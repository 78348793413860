.title {
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.11em;
  color: $white;

  &--h2 {
    font-size: 111px;

    @include lg {
      font-size: 90px; }

    @include sm {
      font-size: 70px; }

    @include xs {
      font-size: 60px; } } }

.text {
  p, ul, ol {
    @include notlast {
      margin-bottom: 30px; } }

  ul, ol {
    margin-left: 1em; }

  ul {
    list-style-type: disc; }

  ol {
    list-style-type: decimal; }

  a {
    text-decoration: underline; } }

