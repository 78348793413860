html,
body {
  height: 100%; }

html {
  font-size: $base-fz + px;
  line-height: 1.6;
  color: $c-text;
  @include reg;
  @include sm {
    font-size: $base-fz-sm + px; } }

body {
  background-color: $background;
  min-width: 320px; }

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit; }

.lazy {
  $this: &;

  &--loaded:not(#{$this}--no-fade-in) {
    animation: fade-in 1s; } }

.container {
  width: 100%;
  max-width: $base-container + px;

  padding-left: $base-container-gap + px;
  padding-right: $base-container-gap + px;

  margin-left: auto;
  margin-right: auto; }

.logo {
  display: inline-block;
  font-size: 0; }

.full-image {
  width: 100%; }

.full-video {
  font-size: 0;

  &__img,
  &__video {
    width: 100%; }

  &__img {
    .no-touch & {

      @include xsmin {
        display: none; } } }

  &__video {
    display: none;

    .no-touch & {
      display: block;

      @include xs {
        display: none; } } } }
