.hero {
  position: relative;
  color: $white;
  padding-top: $header-height + px;
  overflow: hidden;

  @include after {
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    right: 0;
    height: 70px;
    pointer-events: none;
    background-image: linear-gradient(0deg, $background, rgba($background, .3)); }

  &__inner {
    @include windowMinHeight($header-height + px);
    position: relative;
    z-index: 6;
    padding-top: 100px; }

  &__bg {
    @extend %coverdiv;

    img {
      position: absolute;
      object-fit: contain;
      left: 0;
      width: 100%;

      &:not(:nth-child(1)) {
        @media (min-height: 63vw) {
          display: none; }

        @include lg {
          display: none; } }

      &:nth-child(1) {
        @extend %coverdiv;
        @extend %coverimage;

        object-fit: cover;
        display: none;

        @media (min-height: 63vw) {
          display: block; }

        @include lg {
          display: block; }

        @include sm {
          object-position: 70% 50%; } }

      &:nth-child(2) {
        top: 17%;
        z-index: 1; }

      &:nth-child(3) {
        top: 0;
        z-index: 0; }

      &:nth-child(4) {
        z-index: 2;
        top: 13%; }

      &:nth-child(5) {
        z-index: 3;
        bottom: 22%; }

      &:nth-child(6) {
        z-index: 4;
        &[data-processed="true"] {
          bottom: -1%; } }

      &:nth-child(7) {
        z-index: 5;
        bottom: 5%;
        left: 55%;
        width: percentage(605 / $base-desktop);

        @media (max-height: 55vw) {
          width: 25%; } } } }

  &__title {
    font-size: 223px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: .11em;
    max-width: percentage(8 / 12);

    @include xl {
      font-size: 190px; }

    @include lg {
      font-size: 150px; }

    @include md {
      font-size: 120px; }

    @include sm {
      font-size: 90px; }

    @include xs {
      font-size: 70px; }

    @include xxs {
      font-size: 60px; }

    span {
      display: inline-block;

      &:nth-child(2) {
        margin-left: 0.5em; } } }

  &__scroll {
    @extend %flex-center;

    width: 77px;
    height: 77px;

    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 72px;

    font-size: 77px;

    @include sm {
      font-size: 60px;
      bottom: 50px; }

    @include hover-focus {
      color: $c-text; }

    .icon {
      @include tr(color .3s); } } }
