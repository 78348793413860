.social {
  display: flex;
  align-items: center;

  font-size: 30px;
  color: $white;

  margin-left: em(-15, 30);
  margin-right: em(-15, 30);

  &__item {
    display: flex;
    margin-left: em(15, 30);
    margin-right: em(15, 30); }

  &__link {
    display: inline-flex;

    @include hover-focus {
      color: $c-text; }

    .icon {
      @include tr(color .3s); } }

  &--lg {
    font-size: 132px;

    @include xl {
      font-size: 110px; }

    @include lg {
      font-size: 100px; }

    @include md {
      font-size: 80px; }

    @include sm {
      font-size: 60px; }

    @include xs {
      font-size: 50px; }

    @include xxs {
      font-size: 40px; } } }
