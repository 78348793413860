.footer {
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;

    padding-top: 200px;
    padding-bottom: 70px;

    @include lg {
      padding-top: 150px;
      padding-bottom: 40px; }

    @include sm {
      padding-top: 100px;
      padding-bottom: 30px; } } }
