.section {
  padding-top: 60px;
  padding-bottom: 60px;

  &__title {
    margin-bottom: 90px;
    text-align: center; }

  &__inner {
    @extend %row;
    align-items: center;

    margin-left: -15px;
    margin-right: -15px; }

  &__left,
  &__right {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;

    @include lg {
      width: 100%; } }

  &__left {
    @include lg {
      margin-bottom: 30px; } } }
