.header {
  color: $white;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: $header-height + px;

    padding-top: 10px;
    padding-bottom: 10px; }

  &__logo {}

  &__controls {} }
